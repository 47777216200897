import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/compassi/layout"
import SEO from "../components/editions/2020/seo"
import styled from "styled-components"
import RichContentBlockText from "../components/editions/2020/richContentBlockText"
import { Display } from "../components/editions/2020/typography"
import { Container, Row, Col } from "react-grid-system"
import InnerNav from "../components/editions/2020/innerNav"
import { Box } from "theme-ui"
import Sponsors from "../components/compassi/sponsors"
import Footer from "../components/home/blocks/footer"
import { getCustomPage } from "../utils/path"

const Page = ({ data: { page, site } }) => {
  let tmpi18nPaths = []
  site.locales.map(locale => {
    tmpi18nPaths.push({
      locale: locale,
      value: getCustomPage(page, locale),
    })
  })
  const i18nPaths = tmpi18nPaths

  return (
    <Layout locale={page.locale} i18nPaths={i18nPaths} theme="dark">
      <SEO title={page.title} />
      <InnerNav />
      <Wrapper>
        <Container>
          <Row>
            <Col sm={12}>
              <Display>{page.title}</Display>
              <RichContentBlockText html={page.text} />
            </Col>
          </Row>
        </Container>
      </Wrapper>
      {page.content &&
        page.content.map(block => (
          <Box
            as="section"
            key={block.id}
            id={block.id}
            sx={{
              position: "relative",
              zIndex: 99,
              px: [0, 0],
            }}
          >
            {block.model.apiKey === "footer2023" && <Box sx={{ px: [3, 4, 7],py:[4]}}><Footer block={block} /></Box>}
            {block.model.apiKey === "sponsors_compassi" && (
              <Sponsors block={block} />
            )}
          </Box>

        ))}
    </Layout>
  )
}

export default Page

const Wrapper = styled.div`
  padding-top: 100px;
  padding-bottom:100px;
`

export const query = graphql`
  query PageQuery($id: String!) {
    site: datoCmsSite {
      locales
    }
    page: datoCmsPage(id: { eq: $id }) {
      title
      text
      content {
        ... on DatoCmsFooter2023 {
          id
          privacy {
            ... on DatoCmsExternalLink {
              ...ExternalLinkDetails
            }
            ... on DatoCmsInternalLink {
              ...InternalLinkDetails
            }
          }
          cookie {
            ... on DatoCmsExternalLink {
              ...ExternalLinkDetails
            }
            ... on DatoCmsInternalLink {
              ...InternalLinkDetails
            }
          }
          credits {
            ... on DatoCmsExternalLink {
              ...ExternalLinkDetails
            }
            ... on DatoCmsInternalLink {
              ...InternalLinkDetails
            }
          }
          legalInfo
          model {
            apiKey
          }
        }
        ... on DatoCmsSponsorsCompassi {
          id
          content {
            ... on DatoCmsLogoBlock {
              section
              partners {
                id
                name
                text
                whiteLogo {
                  url
                  alt
                }
                url
              }
            }
          }
          model {
            apiKey
          }
        }
      }
    }
  }
`
